import React from 'react'
import { graphql } from 'gatsby'
import { get, groupBy } from 'lodash'

import Person from '../components/Person'

export const query = graphql`
  query PersonQuery($lang: String, $uid: String!, $id: String!) {
    prismic {
      data: allPersons(uid: $uid, lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            name
            role
            photo
            # photoSharp {
            #   childImageSharp {
            #     fluid(maxWidth: 720) {
            #       ...GatsbyImageSharpFluid_withWebp_noBase64
            #     }
            #   }
            # }
            about {
              heading
              body
            }
            social {
              label
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
      companies: allCompanys(lang: $lang, where: { manager: $id }) {
        edges {
          company: node {
            meta: _meta {
              id
              type
              uid
              lang
            }
            name
            logo
            body
            website {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            details {
              heading
              body
            }
            social {
              label
              link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ({ pageContext, ...props }) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  const companies = get(props, 'data.prismic.companies.edges')
  const groupedArticles = groupBy(pageContext.initialArticles, (item) =>
    get(item, 'node.related.meta.id', null)
  )

  return (
    <>
      <Person
        {...data}
        companies={companies}
        groupedArticles={groupedArticles}
      />
    </>
  )
}
