import React, { useEffect, useContext } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Link from '../Link'
import Wysiwyg from '../Wysiwyg'
import Image from '../Image'
import Title from '../Title'
import Company from './Company'
import useTranslation from '../../hooks/useTranslation'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Logo = ({
  photo,
  photoSharp,
  role,
  name,
  social,
  about,
  companies,
  groupedArticles,
}) => {
  const { t } = useTranslation()
  const filteredSocial = social?.filter(({ link, label }) => link && label)
  const filteredAbout = about?.filter(({ heading, body }) => heading && body)

  const [, dispatch] = useContext(StateContext)
  useEffect(() => {
    const y = window.scrollY
    // take action out of render loop to allow for state to change first
    setTimeout(() => {
      window.scrollTo(0, y)
    }, 0)

    dispatch({
      type: 'closeCompanyModal',
    })
  }, [dispatch])

  return (
    <section className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-md-5 col-lg-4 offset-lg-1">
          <Image image={photo} {...photoSharp} className={css.image} />
        </div>
        <div className="col-sm-12 col-md-5 offset-md-1">
          <div className={classNames('bigger', css.role)}>{role}</div>
          <Title content={name} tag="h2" />
          <div className={css.socialWrap}>
            {(filteredSocial || []).map(({ link, label }) => (
              <Link link={link} className={css.linkListItem}>
                {label}
              </Link>
            ))}
          </div>
          {!!(filteredAbout || []).length && (
            <div className={css.aboutWrap}>
              {filteredAbout.map(({ heading, body }) => (
                <div className={css.aboutItem}>
                  <h5 className="biggest"> {heading}</h5>
                  <Wysiwyg content={body} />
                </div>
              ))}
            </div>
          )}

          {!!companies.length && (
            <div className={css.companiesWrap}>
              <h5 className="biggest">{t('investedCompaniesHeading')}</h5>
              {companies &&
                companies.map((item, i) => (
                  <Company
                    {...item}
                    related={groupedArticles[item.company?.meta?.id]}
                    key={item.company?.meta?.uid || i}
                    isFeatured={false}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Logo
