import React, { useCallback, useContext } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import useTranslation from '../../hooks/useTranslation'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Company = ({ company, related }) => {
  const { t } = useTranslation()
  const [, dispatch] = useContext(StateContext)
  const onClick = useCallback(() => {
    dispatch({
      type: 'openCompanyModal',
      updates: { data: { ...company, related } },
    })
  }, [company, dispatch])

  return (
    company?.name && (
      <button
        className={classNames(css.companyButton)}
        onClick={onClick}
        key={company.meta.id}
        type="button"
      >
        <span className="sr-only">{t('viewDetails')}:</span>
        <span className={css.companyName}>{asText(company.name)}</span>
      </button>
    )
  )
}

export default Company
